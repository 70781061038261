import { Component, OnInit } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { SiteModel } from 'src/app/common/core/api-interfaces/site-interfaces';
import { GlobalService } from 'src/app/services/global.service';
import { SiteListService } from 'src/app/services/site-list/site-list.service';

@Component({
  selector: 'app-drawer-menu',
  templateUrl: './drawer-menu.component.html',
  styleUrls: ['./drawer-menu.component.scss']
})
export class DrawerMenuComponent implements OnInit {
  entityListData: SiteModel[];
  constructor(private navCtrl: NavController,
    private menuCtrl: MenuController,
    private storage: Storage,
    private global: GlobalService,
    private siteService: SiteListService,
  ) { }

  ngOnInit() {
    this.global.ready()
      .subscribe(ready => {
        if (!ready) {
          return;
        }
        this.getAllEntityList();
        console.log("Menu Component ready");
      });
  }

  getAllEntityList() {
    this.siteService.getEntityList().subscribe(
      (data) => {
        this.entityListData = data;
      }, (error) => {
        // this.alertCtrl.create({
        //   header: 'Error',
        //   message: error.error,
        //   buttons: ['Ok']
        // }).then(alert => alert.present());
      }
    );
  }

  navigate(a: string): void {
    this.menuCtrl.close();
    this.navCtrl.navigateForward(a);
  }

  logout() {
    this.menuCtrl.close();
    this.storage.clear().then(res => {
      this.navCtrl.navigateRoot('login');
    });
  }
}
